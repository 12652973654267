<template>
    <div class="row">
        <div class="col-12 col-md-6 q-pa-xs">
            <q-select
                ref="id_natureza_profissional"
                v-model="dados.id_natureza_profissional"
                :options="naturezaProfissionalOptions"
                label="Natureza Profissional"
                square
                filled
                lazy-rules
                :rules="[
                    val => !!val || 'É necessário preencher este campo'
                ]"
            />
        </div>
        <div class="col-12 col-md-6 q-pa-xs">
            <q-input
                ref="renda_mensal"
                v-model="dados.renda_mensal"
                label="Renda *"
                mask="###.###.###,##"
                reverse-fill-mask
                unmasked-value
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo',
                ]"
            />
        </div>
    </div>
    <div class="row">
        <div
            class="col-12 col-md-4 q-pa-xs"
            v-if="!isAposentadoPensionista"
        >
            <q-input
                ref="empresa_nome"
                v-model="dados.empresa_nome"
                label="Nome da Empresa *"
                square
                filled
                lazy-rules
                :rules="[
                    val => !!val || 'É necessário preencher este campo'
                ]"
                maxlength="30"
                counter
            />
        </div>
        <div
            class="col-12 col-md-4 q-pa-xs"
            v-if="!isAposentadoPensionista"
        >
        <q-input
            ref="empresa_cep"
            v-model="dados.empresa_cep"
            label="CEP da empresa"
            mask="##.###-###"
            unmasked-value
            square
            filled
            maxlength="11"
            counter
            lazy-rules
            :rules="[
                (val) => {
                    if (val) {
                        return val.length >= 8 || 'Este campo deve ter 8 caracteres'
                    }
                }
            ]"
            />
        </div>
        <div
            class="col-12 col-md-4 q-pa-xs"
            v-if="!isAposentadoPensionista"
        >
            <q-input
                ref="empresa_endereco"
                v-model="dados.empresa_endereco"
                label="Endereço da empresa"
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo',
                ]"
                maxlength="30"
                counter
            />
        </div>
    </div>
    <div class="row">
        <div
            class="col-12 col-md-4 q-pa-xs"
            v-if="!isAposentadoPensionista"
        >
            <q-input
                ref="empresa_cidade"
                v-model="dados.empresa_cidade"
                label="Cidade da empresa"
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo',
                ]"
                maxlength="30"
                counter
            />
        </div>
        <div
            class="col-12 col-md-4 q-pa-xs q-pb-md"
            v-if="!isAposentadoPensionista"
        >
            <q-select
                ref="empresa_uf"
                v-model="dados.empresa_uf"
                :options="estadoOptions"
                label="Estado da empresa"
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo'
                ]"
            />
        </div>
        <div
            class="col-12 col-md-4 q-pa-xs q-pb-md"
            v-if="!isAposentadoPensionista"
        >
            <q-input
                ref="empresa_telefone"
                square
                filled
                v-model="dados.empresa_telefone"
                label="Telefone da empresa"
                type="tel"
                mask="(##) #########"
                unmasked-value
                lazy-rules
                :rules="[
                    (val) => val.length >= 10 || 'Informe um telefone válido'
                ]"
            />
        </div>
    </div>
    <div class="row">
        <div
            class="col-12 col-md-4 q-pa-xs"
            v-if="!isAposentadoPensionista"
        >
            <q-input
                ref="empresa_funcao"
                v-model="dados.empresa_funcao"
                label="Função na empresa *"
                square
                filled
                lazy-rules
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo',
                ]"
                maxlength="20"
                counter
            />
        </div>
        <div
            class="col-12 col-md-4 q-pa-xs"
            v-if="!isAposentadoPensionista"
        >
            <q-input
                ref="empresa_admissao"
                v-model="dados.empresa_admissao"
                label="Data de Admissão (Mes/Ano) *"
                square
                filled
                lazy-rules
                mask="##/####"
                :rules="[
                    (val) => !!val || 'É necessário preencher este campo',
                    (val) => validaData(val) || 'Data inválida'
                ]"
                maxlength="7"
                counter
            />
        </div>
    </div>
    <q-stepper-navigation>
        <div class="row justify-between">
            <q-btn
                id="btnVoltar"
                class="q-ml-sm"
                color="primary"
                flat
                icon="navigate_before"
                @click="voltar"
                label="Voltar"
            />
            <q-btn
                id="btnProximo"
                class="q-ml-sm"
                color="primary"
                icon-right="navigate_next"
                @click="proximo"
                label="Próximo"
            />
        </div>
    </q-stepper-navigation>
</template>

<script>
import MixinMessages from '@/mixins/mixinMessages'
import NaturezaProfissionalService from '@/services/natureza_profissional'
import CEPService from '@/services/cep'
import moment from 'moment'

export default {
    name: 'stepRenda',

    mixins: [
        MixinMessages
    ],

    emits: ['proximo', 'voltar', 'update:renda'],

    props: {
        renda: {
            type: Object,
            required: true
        },
        idManutencao: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            naturezaProfissionalOptions: [],
            estadoOptions: [],
            dados: {
                renda_mensal: '',
                id_natureza_profissional: '',
                empresa_nome: '',
                empresa_cidade: '',
                empresa_telefone: '',
                empresa_funcao: '',
                empresa_cep: '',
                empresa_uf: '',
                empresa_endereco: '',
                empresa_admissao: '',
            },
        }
    },

    computed: {
        isAposentadoPensionista() {
            return this.dados.id_natureza_profissional ? this.dados.id_natureza_profissional.value === 7 : false
        }
    },

    watch: {
        'dados.empresa_cep': function(value) {
            this.buscaCEP(value)
        },

        dados: {
            deep: true,
            handler(value) {
                this.$emit('update:renda', value)
            }
        },

        renda: {
            deep: true,
            handler(value) {
                this.dados = value
            }
        }
    },

    mounted() {
        this.getNaturezaProfissional()
        this.getEstados()
    },

    methods: {
        formataValor(value) {
            const valorFormatado = String(value).length > 2 ? String(value).slice(0, -2) + '.' + String(value).slice(-2) : value
            
            const currencyFormatter = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            
            return currencyFormatter.format(parseFloat(valorFormatado));
        },

        getNaturezaProfissional() {
            NaturezaProfissionalService.get()
                .then(({ data }) => {
                    this.naturezaProfissionalOptions = data.map(element => ({
                        label: element.descricao,
                        value: element.id
                    }))
                }).catch(err => {
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Erro ao buscar as naturezas profissionais',
                        error: err
                    })
                })
        },

        getEstados() {
            this.showLoading({ message: 'Buscando estados, Aguarde!' })
            CEPService.getEstados()
                .then(({ data }) => {
                    this.hideLoading()
                    this.estadoOptions = []
                    this.estadoOptions = [
                        ...data.map(el => ({
                            value: el.sigla,
                            label: el.sigla
                        }))
                    ]
                })
                .catch(e => {
                    this.hideLoading()
                    console.log(e)
                })
        },

        buscaCEP(value) {
            const cep = value.replace(/[^\d]+/g, '')
            if (cep.length === 8) {
                this.showLoading({ message: 'Buscando dados do CEP! Por favor, Aguarde!' })
                CEPService.getCep(cep)
                    .then(({ data }) => {
                        this.dados = {
                            ...this.dados,
                            empresa_endereco: data.logradouro.toLowerCase().replace(
                                data.logradouro.substr(0, data.logradouro.indexOf(' ')).toLowerCase(), '').trim(),
                            empresa_uf: data.estado,
                            empresa_bairro: data.bairro,
                            empresa_cidade: data.cidade
                        }
                        this.hideLoading()
                    })
                    .catch(e => {
                        this.hideLoading()
                        if (e.response.status === 404) {
                            this.warningMessage({
                                title: 'Ops, algo de errado aconteceu!',
                                message: 'CEP não encontrado'
                            })
                        } else {
                            this.warningMessage({
                                title: 'Ops, algo de errado aconteceu!',
                                message: 'Não foi possível autocompletar os campos. Preencha o endereço manualmente.'
                            })
                        }
                    })
            }
        },

        validaCampos() {
            let errors = []
            if (!this.isAposentadoPensionista) {
                this.$refs.id_natureza_profissional.validate()
                this.$refs.empresa_nome.validate()
                this.$refs.empresa_funcao.validate()
                this.$refs.empresa_admissao.validate()
                this.$refs.renda_mensal.validate()

                errors = [
                    this.$refs.id_natureza_profissional.hasError,
                    this.$refs.empresa_nome.hasError,
                    this.$refs.empresa_funcao.hasError,
                    this.$refs.empresa_admissao.hasError,
                    this.$refs.renda_mensal.hasError
                ]

                if (String(this.dados.empresa_telefone).length > 0) {
                    this.$refs.empresa_telefone.validate()
                    errors.push(this.$refs.empresa_telefone.hasError)
                } else {
                    this.$refs.empresa_telefone.resetValidation()
                }
            } else {
                this.$refs.id_natureza_profissional.validate()
                this.$refs.renda_mensal.validate()

                errors = [
                    this.$refs.id_natureza_profissional.hasError,
                    this.$refs.renda_mensal.hasError
                ]
            }

            if (errors.includes(true)) {
                return false
            }
            return true
        },

        proximo() {
            if (this.validaCampos()) {
                this.$q.dialog({
                    title: 'Atenção',
                    message: `Você confirma que as informações seguintes estão corretas?<br />
                        <strong style="text-transform: uppercase;">Salário:</strong> ${this.formataValor(this.dados.renda_mensal)}<br />
                        <strong style="text-transform: uppercase;">Natureza Profissional:</strong> ${this.dados.id_natureza_profissional.label}<br />`,
                    ok: {
                        label: 'Confirmar',
                        color: 'primary'
                    },
                    cancel: {
                        label: 'Corrigir',
                        color: 'secondary'
                    },
                    persistent: true,
                    html: true
                })
                    .onOk(() => {
                        this.$emit('proximo')
                    })
                    .onCancel(() => {})
            }
        },

        voltar() {
            this.$emit('voltar')
        },
        
        validaData(data) {
            const currentDate = moment();
            const minDate = moment('01/01/1900', 'DD/MM/YYYY');

            const dataValida = moment(`01/${data}`, 'DD/MM/YYYY', true);

            return (
                dataValida.isValid() &&
                dataValida.isSameOrBefore(currentDate) &&
                dataValida.isSameOrAfter(minDate)
            );
        }

    }
}

</script>