<template>
  <div class="camera-container">
    <div v-if="!photo">
      <div :class="isMobile ? 'video-mobile-wrapper' : 'video-wrapper'">
        <div v-if="mascara" class="overlay-image">
          <img :src="mascaraSelfie" alt="Overlay Image" />
        </div>
        <video
          :class="isMobile ? 'video-mobile' : 'video'"
          ref="video"
          autoplay
          playsinline
          :width="isMobile ? 300 : 330"
          :height="isMobile ? 400 : 250"
        />
        <div class="controls-camera">
          <q-btn
            round
            color="primary"
            icon="photo_camera"
            @click="capturePhoto"
          />
        </div>
        <div class="controls-camera">
          <q-btn
            round
            color="primary"
            icon="photo_camera"
            @click="capturePhoto"
          />
        </div>
      </div>
      <q-select
        :class="`${
          isMobile ? 'camera-mobile-selector' : 'camera-selector'
        } q-mt-sm`"
        v-if="cameras.length > 1"
        v-model="selectedCameraId"
        :options="cameras"
        option-label="label"
        option-value="value"
        label="Selecione a câmera"
        square
        filled
      />
      <q-btn
        :class="isMobile ? 'button-select-file-mobile' : 'button-select-file'"
        @click="selectFile"
        color="primary"
        label="Buscar Imagem (PNG, JPEG ou PDF)"
      />
      <input
        type="file"
        ref="fileInput"
        @change="onFileChange"
        accept=".png, .jpeg, .jpg, .pdf"
        style="display: none"
      />
    </div>

    <!-- Mostrar imagem capturada ou carregada -->
    <div v-else-if="extension !== 'pdf'">
      <div :class="isMobile ? 'image-mobile-wrapper' : 'image-wrapper'">
        <img
          :src="photo"
          alt="Captured Photo"
          :class="isMobile ? 'captured-image-mobile' : 'captured-image'"
        />
        <div class="controls">
          <q-btn round color="secondary" icon="refresh" @click="clearData" />
          <q-btn round color="primary" icon="fullscreen" @click="viewImage" />
        </div>
      </div>
    </div>

    <!-- Mostrar PDF carregado -->
    <div v-else>
      <div class="pdf-wrapper">
        <iframe
          :src="photo"
          width="330"
          height="250"
          frameborder="0"
          class="pdf-viewer"
        ></iframe>
        <div class="controls">
          <q-btn round color="secondary" icon="refresh" @click="clearData" />
          <q-btn round color="primary" icon="fullscreen" @click="viewImage" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParametroPropostaService from "@/services/parametrosProposta";
import MixinMessages from "@/mixins/mixinMessages";
import { EventBus } from "@/plugins/eventBus";

export default {
  props: {
    mascara: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      photo: null,
      extension: "png",
      cameras: [],
      selectedCameraId: null,
      tamanhoMaximoDocumento: 0,
      currentStream: null,
      isStartingStream: false,
    };
  },

  mixins: [MixinMessages],

  emits: ["reset"],

  mounted() {
    EventBus.on("stop-camera", this.stopCameraStream);
    EventBus.on("reset-camera", this.clearData);
    this.buscaParametros();
    this.getCameras();
  },

  beforeUnmount() {
    EventBus.off("stop-camera", this.stopCameraStream);
    EventBus.off("reset-camera", this.clearData);
  },

  beforeRouteLeave(to, from, next) {
    this.stopCameraStream();
    next();
  },

  watch: {
    selectedCameraId: function (value) {
      if (value) {
        this.startCamera(value);
      }
    },
  },

  computed: {
    isMobile() {
      return this.$q.platform.is.mobile;
    },

    mascaraSelfie() {
      return String(process.env.VUE_APP_EMPRESA).toLowerCase() === "pomp" ? './face-pompeia.png' : './face-gang.png';
    }
  },

  methods: {
    buscaParametros() {
      ParametroPropostaService.getParametro(
        "MANUTENCAO.TAMANHO_MAXIMO_DOCUMENTO"
      )
        .then(({ data }) => {
          this.tamanhoMaximoDocumento = parseInt(data[0].valor);
        })
        .catch((error) => {
          console.log(error);
          this.errorMessage({
            title: "Ops, algo de errado aconteceu!",
            message:
              "Erro ao buscar parâmetro MANUTENCAO.TAMANHO_MAXIMO_DOCUMENTO",
            error,
          });
        });
    },

    getCameras() {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          this.cameras = devices
            .filter((device) => device.kind === "videoinput")
            .map((camera) => ({
              label: camera.label || `Câmera ${this.cameras.length + 1}`,
              value: camera.deviceId,
            }));
          if (this.cameras.length > 0) {
            this.selectedCameraId = this.cameras[0];
            this.startCamera(this.selectedCameraId.value);
          }
        })
        .catch((error) => {
          console.log(error);
          this.errorMessage({
            title: "Ops, algo de errado aconteceu!",
            message: "Erro ao listar as câmeras",
            error,
          });
        });
    },

    startCamera(deviceId) {
      const device = deviceId?.value || deviceId;

      navigator.permissions
        .query({ name: "camera" })
        .then((permissionStatus) => {
          if (permissionStatus.state === "granted") {
            this.startCameraStream(device);
          } else {
            this.startCameraStream(device);
          }
        })
        .catch((error) => {
          this.startCameraStream(device);
          console.log(error);
          this.errorMessage({
            title: "Ops, algo de errado aconteceu!",
            message: "Erro ao verificar a permissão da câmera",
            error,
          });
        });
    },

    async startCameraStream(device) {
      if (this.isStartingStream) return;
      this.stopCameraStream();
      this.isStartingStream = true;

      const constraints = {
        video: device ? { deviceId: { exact: device } } : true,
      };

      try {
        this.currentStream = await navigator.mediaDevices.getUserMedia(
          constraints
        );
        const video = this.$refs.video;
        if (video && this.currentStream) {
          video.srcObject = this.currentStream;
          await video.play();
        }
      } catch (error) {
        console.log(error);
        if (error.name === "NotAllowedError") {
          console.error("Permissão para acessar a câmera foi negada.", error);
        } else if (error.name === "OverconstrainedError") {
          console.error(
            "As restrições especificadas para a câmera não são suportadas.",
            error
          );
        } else {
          this.errorMessage({
            title: "Ops, algo de errado aconteceu!",
            message: "Erro ao acessar a câmera.",
            error,
          });
        }
      } finally {
        this.isStartingStream = false;
      }
    },

    stopCameraStream() {
      if (this.currentStream) {
        this.currentStream.getTracks().forEach((track) => track.stop());
        this.currentStream = null;
      }
      const video = this.$refs.video;
      if (video) {
        video.srcObject = null;
      }
    },

    switchCamera() {
      this.clearData();
    },

    capturePhoto() {
      const video = this.$refs.video;
      const canvas = document.createElement("canvas");
      canvas.width = this.isMobile ? 300 : 330;
      canvas.height = this.isMobile ? 400 : 250;
      const context = canvas.getContext("2d");

      try {
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        this.photo = canvas.toDataURL("image/png", 0.6);
        this.extension = "png";

        this.$emit("photo", {
          base64: this.photo,
          extensao: this.extension,
        });
        this.stopCameraStream();
      } catch (error) {
        console.log(error);
        this.errorMessage({
          title: "Ops, algo de errado aconteceu!",
          message: "Erro ao capturar a foto",
          error,
        });
      }
    },

    selectFile() {
      this.$refs.fileInput.click();
    },

    onFileChange(event) {
      this.stopCameraStream();
      const file = event.target.files[0];
      if (file) {
        this.clearData();
        const extensao = file.type.split("/")[1];
        if (file.size > this.tamanhoMaximoDocumento * 1024) {
          this.warningMessage({
            title: "Ops, algo de errado aconteceu!",
            message: `O arquivo excede o limite de ${this.tamanhoMaximoDocumento} KB.`,
          });
          this.clearData();
          return;
        }

        if (extensao === "pdf") {
          this.extension = "pdf";
          this.convertToBase64(file, "photo");
        } else if (extensao === "png" || extensao === "jpeg") {
          this.extension = extensao;
          this.convertToBase64(file, "photo");
        } else {
          this.warningMessage(
            "Atenção",
            "Tipo de arquivo não suportado. Escolha PNG, JPEG ou PDF."
          );
          this.clearData();
        }
      }
    },

    convertToBase64(file, target) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this[target] = e.target.result;
        this.$emit("photo", {
          base64: e.target.result,
          extensao: this.extension,
        });
      };
      reader.readAsDataURL(file);
    },

    clearData() {
      this.photo = null;
      this.extension = "png";
      this.$emit("reset")
      this.$nextTick(() => {
        if (this.selectedCameraId) {
          this.startCamera(this.selectedCameraId.value);
        }
      });
    },

    viewImage() {
      if (this.extension === "pdf") {
        const base64Data = this.photo.split(",")[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);
        window.open(blobUrl, "_blank");
      } else {
        const imageUrl = this.photo;
        const newWindow = window.open("", "_blank");
        newWindow.document.write(
          `<html><body><img src="${imageUrl}" alt="Image" style="width:100%; height:auto;"></body></html>`
        );
        newWindow.document.close();
      }
    },
  },
};
</script>

<style scoped>
.camera-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.camera-selector {
  width: 330px;
  overflow: hidden;
}

.camera-mobile-selector {
  width: 300px;
  overflow: hidden;
}

.video-wrapper,
.image-wrapper,
.pdf-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 330px;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.video-mobile-wrapper,
.image-mobile-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 300px;
  max-height: 400px;
  width: 100%;
  height: 100%;
}

.captured-image {
  border-radius: 10px;
  width: 330px;
  height: 250px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.captured-image-mobile {
  border-radius: 10px;
  width: 300px;
  height: 400px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.video {
  border-radius: 10px;
  max-width: 330px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.video-mobile {
  border-radius: 10px;
  max-width: 300px;
  max-height: 400px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.pdf-viewer {
  border-radius: 10px;
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
  margin-top: 10px;
}

.controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 0 10px;
}

.controls-camera {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
  padding: 0 10px;
}

.button-select-file {
  width: 330px;
  margin-top: 10px;
}

.button-select-file-mobile {
  width: 300px;
  margin-top: 10px;
}

.overlay-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.overlay-image img {
  max-width: 100px;
}
</style>
