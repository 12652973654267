<template>
    <div>
        <div class="row justify-center q-ma-sm">
            <div style="width: 100%; max-width: 1200px;">
                <q-stepper
                    v-if="true"
                    flat
                    id="stepper"
                    ref="stepper"
                    v-model="step"
                    color="primary"
                    :no-header-navigation="true"
                    :vertical="isMobile || mxScreen_IsLowResolution"
                    alternative-labels
                    contractable
                    animated
                >
                    <q-step
                        id="stepperContato"
                        :name="1"
                        icon="phone"
                        active-icon="phone"
                        done-icon="done_all"
                        done-color="primary"
                        title="Dados de contato"
                        :done="step > 1"
                    >
                        <step-contato
                            v-model:contato="contato"
                            :id-manutencao="id_manutencao"
                            @proximo="proximoContato"
                        />
                    </q-step>
                    <q-step
                        id="stepperToken"
                        :name="2"
                        icon="key"
                        active-icon="key"
                        done-icon="done_all"
                        done-color="primary"
                        title="Token"
                        :done="step > 2"
                    >
                        <step-token
                            v-model:token="token"
                            :telefone-celular="contato.telefone_celular"
                            :id-manutencao="id_manutencao"
                            :tempo-reenvio-sms="tempoReenviarToken"
                            @proximo="proximoToken"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        v-if="!isTotem"
                        id="stepperSelfie"
                        :name="3"
                        icon="face"
                        active-icon="face"
                        done-icon="done_all"
                        done-color="primary"
                        title="Selfie"
                        :done="step > 3"
                    >
                        <step-selfie
                            v-model:foto="foto"
                            @proximo="proximoSelfie"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        v-if="!isTotem"
                        id="stepperDocumentoIdentificacao"
                        :name="4"
                        icon="description"
                        active-icon="description"
                        done-icon="done_all"
                        done-color="primary"
                        title="Documento de Identificação"
                        :done="step > 4"
                    >
                        <step-documento-identificacao
                            v-model:documentoIdentificacaoFrente="documentoIdentificacaoFrente"
                            v-model:documentoIdentificacaoVerso="documentoIdentificacaoVerso"
                            @proximo="proximoDocumentoIdentificacao"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        id="stepperAtualizacaoEndereco"
                        :name="5"
                        icon="home"
                        active-icon="home"
                        done-icon="done_all"
                        done-color="primary"
                        title="Atualização de Endereço"
                        :done="step > 5"
                    >
                        <step-atualizacao-endereco
                            v-model:endereco="endereco"
                            :id-manutencao="id_manutencao"
                            @proximo="proximoEndereco"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        v-if="!isTotem"
                        id="stepperComprovanteEndereco"
                        :name="6"
                        icon="description"
                        active-icon="description"
                        done-icon="done_all"
                        done-color="primary"
                        title="Comprovante de Endereço"
                        :done="step > 6"
                    >
                        <step-comprovante-endereco
                            @OnComprovanteEndereco="(value) => comprovanteEndereco = value"
                            @OnComprovanteEnderecoAdicional="(value) => comprovanteEnderecoAdicional = value"
                            @proximo="proximoComprovanteEndereco"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        id="stepperAtualizacaoRenda"
                        :name="7"
                        icon="attach_money"
                        active-icon="attach_money"
                        done-icon="done_all"
                        done-color="primary"
                        title="Atualização de Renda"
                        :done="step > 7"
                    >
                        <step-atualizacao-renda
                            v-model:renda="renda"
                            :id-manutencao="id_manutencao"
                            @proximo="proximoRenda"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        v-if="!isTotem"
                        id="stepperComprovanteRenda"
                        :name="8"
                        icon="assignment"
                        active-icon="assignment"
                        done-icon="done_all"
                        done-color="primary"
                        title="Comprovante de Renda"
                        :done="step > 8"
                    >
                        <step-comprovante-renda
                            @OnComprovanteRenda="(value) => comprovanteRenda = value"
                            @OnComprovanteRendaAdicional="(value) => comprovanteRendaAdicional = value"
                            @proximo="proximoComprovanteRenda"
                            @voltar="voltarStep"
                        />
                    </q-step>
                    <q-step
                        id="stepperAnalise"
                        :name="9"
                        icon="account_balance"
                        active-icon="account_balance"
                        done-icon="done_all"
                        done-color="primary"
                        title="Análise"
                        :done="step > 9"
                    >
                        <step-analise
                            :status="statusManutencao"
                            :processando-motor="processandoMotor"
                            :tempo-tentativa-manutencao="tempoTentativaManutencao"
                            :tempo-reprocessar-manutencao="tempoReprocessarManutencao"
                            :observacao-loja="observacaoLoja"
                            @reprocessar="processarMotor"
                        />
                    </q-step>
                </q-stepper>
            </div>
        </div>
        <modal-termo-privacidade
            ref="modalTermo"
            id="TermoPrivacidade"
            @termoAceito="termoAceito"
        />
    </div>
</template>


<script>

import StepContato from '@/components/ManutencaoCliente/steps/contato'
import StepToken from '@/components/ManutencaoCliente/steps/token'
import StepSelfie from '@/components/ManutencaoCliente/steps/selfie'
import StepDocumentoIdentificacao from '@/components/ManutencaoCliente/steps/documentoIdentificacao'
import StepAnalise from '@/components/ManutencaoCliente/steps/analise'
import StepAtualizacaoEndereco from '@/components/ManutencaoCliente/steps/atualizacaoEndereco'
import StepComprovanteEndereco from '@/components/ManutencaoCliente/steps/comprovanteEndereco'
import StepAtualizacaoRenda from '@/components/ManutencaoCliente/steps/atualizacaoRenda'
import StepComprovanteRenda from '@/components/ManutencaoCliente/steps/comprovanteRenda'
import ModalTermoPrivacidade from '@/components/ManutencaoCliente/modalTermoPrivacidade'
import ManutencaoClienteService from '@/services/manutencao'
import ParametroPropostaService from '@/services/parametrosProposta'
import MixinMessages from '@/mixins/mixinMessages'
import store from '@/store/index'
import login from '@/plugins/login'
import moment from 'moment'
import { mixinScreen } from 'lins-mixins'
import { EventBus } from '@/plugins/eventBus';

const MANUTENCAO_STATUS_AGUARDANDO_ANALISE = 3
const MANUTENCAO_STATUS_EM_ANALISE = 4 
const MANUTENCAO_STATUS_AGUARDANDO_MOTOR = 5 
const MANUTENCAO_STATUS_REPROVADO_AUTOMATICAMENTE = 6
const MANUTENCAO_STATUS_REPROVADO_MESA = 7
const MANUTENCAO_STATUS_AGUARDANDO_INTEGRAR = 8

export default {
    name: 'ManutencaoCliente',

    components: {
        StepContato,
        StepToken,
        StepSelfie,
        StepDocumentoIdentificacao,
        StepAtualizacaoEndereco,
        StepComprovanteEndereco,
        StepAtualizacaoRenda,
        StepComprovanteRenda,
        StepAnalise,
        ModalTermoPrivacidade
    },

    mixins: [
        mixinScreen,
        MixinMessages
    ],

    data() {
        return {
            step: 1,
            id_manutencao: null,
            cpf: store.getters.getUsuarioLogado.cpf,
            statusManutencao: null,
            statusAnalise: null,
            tempoReenviarToken: 1,
            tempoTentativaManutencao: 0,
            tempoReprocessarManutencao: 0,
            contato: {
                telefone_celular: null,
                email: null,
            },
            token: null,
            token_validado: false,
            foto: {
                base64: null,
                extensao: null,
                tipo: null
            },
            documentoIdentificacaoFrente: {
                base64: null,
                extensao: null,
                tipo: null
            },
            documentoIdentificacaoVerso: {
                base64: null,
                extensao: null,
                tipo: null
            },
            endereco: {
                endereco_cep: null,
                endereco_cidade: null,
                endereco_logradouro: null,
                endereco_uf: null,
                endereco_numero: null,
                endereco_bairro: null,
                endereco_complemento: null
            },
            comprovanteEndereco: {
                base64: null,
                extensao: null,
                tipo: null
            },
            comprovanteEnderecoAdicional: {
                base64: null,
                extensao: null,
                tipo: null
            },
            renda: {
                renda_mensal: null,
                empresa_admissao: null,
                id_natureza_profissional: null,
                empresa_nome: null,
                empresa_cidade: null,
                empresa_telefone: null,
                empresa_funcao: null
            },
            comprovanteRenda: {
                base64: null,
                extensao: null,
                tipo: null
            },
            comprovanteRendaAdicional: {
                base64: null,
                extensao: null,
                tipo: null
            },
            statusAtualizacaoCadastro: [0, 2, 3, 7],
            statusAtualizacaoB2E: [1],
            processandoMotor: false,
            observacaoLoja: null
        }
    },

    computed: {
        isMobile() {
            return this.$q.platform.is.mobile
        },

        usuario() {
            return login.usuarioLogado()
        },

        rede() {
            return String(process.env.VUE_APP_EMPRESA).toLowerCase() === 'pomp' ? 1 : 4
        },

        isTotem() {
          return Number(process.env.VUE_APP_TOTEM);
        }
    },

    mounted() {
        this.buscaDados()
    },

    unmounted() {
        this.stopCameraGlobally()
    },

    methods: {
        stopCameraGlobally() {
            EventBus.emit('stop-camera');
        },

        buscaDados() {
            Promise.all([
                ParametroPropostaService.getParametro('MANUTENCAO.TEMPO_REENVIAR_TOKEN'),
                ParametroPropostaService.getParametro('MANUTENCAO.TEMPO_TENTATIVA'),
                ParametroPropostaService.getParametro('MANUTENCAO.TEMPO_BOTAO_REPROCESSAR'),
                ManutencaoClienteService.get({
                    id_cliente: this.usuario.id,
                    rede: this.rede,
                    status__in: '0,3,4,5,6,7',
                    fields: 'id,status,analise_credito,updated_at'
                })
            ])
            .then(([{ data: tempoReenviarToken }, { data: tempoTentativa }, { data: tempoReprocessar }, { data: manutencoes }]) => {
                    this.tempoReenviarToken = parseInt(tempoReenviarToken[0].valor)
                    this.tempoTentativaManutencao = parseInt(tempoTentativa[0].valor)
                    this.tempoReprocessarManutencao = parseInt(tempoReprocessar[0].valor)
                    this.validaManutencao(manutencoes)
                })
                .catch(e => {
                    this.errorMessage({
                        title: 'Ops, algo de errado aconteceu!',
                        message: 'Não foi possivel carregar os parametros ou a manutenção. Contate a central de credito',
                        error: e
                    })
                    this.$router.push('/')
                })
        },

        proximoContato() {
            this.patchManutencao({
                email: this.contato.email ? this.contato.email.toLowerCase() : null,
                telefone_celular: this.contato.telefone_celular.replace(/[^0-9]/g,'')
            })
        },

        diferencaHoras(data) {
            const now = moment();
            const date = moment(data);
            return Math.abs(date.diff(now, 'hours'));
        },

        validaManutencao(manutencoes) {
            if (manutencoes.length > 0) {
                const manutencao_inicio = manutencoes.filter(manutencao => manutencao.status === 0)
                const manutencao_analise = manutencoes.filter(manutencao => [
                    MANUTENCAO_STATUS_AGUARDANDO_ANALISE,
                    MANUTENCAO_STATUS_EM_ANALISE,
                    MANUTENCAO_STATUS_AGUARDANDO_MOTOR,
                    MANUTENCAO_STATUS_REPROVADO_AUTOMATICAMENTE,
                    MANUTENCAO_STATUS_REPROVADO_MESA
                ].includes(manutencao.status))
                if (manutencao_inicio.length > 0) {
                    this.validaManutencaoInicio(manutencao_inicio[0])
                } else if (manutencao_analise.length > 0) {
                    this.validaManutencaoAnalise(manutencao_analise[manutencao_analise.length - 1])
                }
            } else {
                this.$refs.modalTermo.open()
            }
        },

        validaManutencaoInicio(manutencao) {
            this.id_manutencao = manutencao.id
            this.statusManutencao = manutencao.status
            this.statusAnalise = manutencao.analise_credito
            if (this.statusAtualizacaoCadastro.includes(this.statusAnalise)) {
                this.step = 1
                this.hideLoading()
            } else if (this.statusAtualizacaoB2E.includes(this.statusAnalise)) {
                this.showLoading({ message: 'Processando os dados da manutenção! Por favor, Aguarde!' })
                this.processarMotor(() => {
                    this.step = 9
                    this.hideLoading()
                })
            }
        },

        validaManutencaoAnalise(manutencao) {
            if ([MANUTENCAO_STATUS_REPROVADO_AUTOMATICAMENTE, MANUTENCAO_STATUS_REPROVADO_MESA].includes(manutencao.status) &&
                this.diferencaHoras(manutencao.updated_at) >= this.tempoTentativaManutencao
            ) {
                this.$refs.modalTermo.open()
            } else {
                this.id_manutencao = manutencao.id
                this.statusManutencao = manutencao.status
                this.step = 9
                this.hideLoading()
            }
        },

        getDadosManutencao() {
            this.showLoading({ message: 'Carregando os dados! Por favor, Aguarde!' })
            ManutencaoClienteService.getByID(this.id_manutencao, {
                fields: 'status,analise_credito,eventos'
            })
                .then(({ data}) => {
                    this.statusManutencao = data.status
                    this.statusAnalise = data.analise_credito
                    this.observacaoLoja = data.eventos[0].mensagem.observacao_loja
                    if (this.statusAnalise === 1) {
                        this.step = 9
                    }
                })
                .catch(() => {
                    this.errorMessage({ 
                        title:'Ops! Não foi possivel carregar os dados do usuario',
                        message:"Não foi possivel carregar os dados do usuario na api. Contate a central de credito",
                    })
                })
                .finally(() => {
                    this.hideLoading()
                })
        },

        proximoToken() {
            if (!this.token_validado) {
                this.showLoading({ message: 'Validando o Token! Por favor, Aguarde!' })
                ManutencaoClienteService
                    .postValidaToken(this.id_manutencao, { token: String(this.token || "").padEnd(6, '0') })
                    .then(() => {
                        this.token_validado = true
                        this.proximoStep()
                    }).catch((error) => {
                        this.errorMessage({ 
                            title:'Ops! Algo de errado aconteceu!',
                            message: 'Verifique o token e tente novamente!',
                            error: error
                        })
                    })
                    .finally(() => {
                        this.hideLoading()
                    })
            } else {
                this.proximoStep()
            }
        },

        proximoSelfie() {
            this.stopCameraGlobally()
            if (this.foto?.base64) {
                this.postDocumento({
                    documento_base64: this.foto.base64,
                    tipo: this.foto.tipo
                })
            } else {
                this.proximoStep()
            }
        },

        proximoDocumentoIdentificacao() {
            this.stopCameraGlobally()
            if (this.documentoIdentificacaoFrente?.base64) {
                this.postDocumento({
                    documento_base64: this.documentoIdentificacaoFrente.base64,
                    tipo: this.documentoIdentificacaoFrente.tipo
                }, (next) => {
                    if (this.documentoIdentificacaoVerso && this.documentoIdentificacaoVerso.base64) {
                        this.postDocumento({
                            documento_base64: this.documentoIdentificacaoVerso.base64,
                            tipo: this.documentoIdentificacaoVerso.tipo
                        })
                    } else {
                        next()
                    }
                })
            } else {
                this.proximoStep()
            }
        },

        proximoEndereco() {
            this.showLoading({ message: 'Salvando seus dados! Por favor, Aguarde!' })
            this.patchManutencao({
                ...this.endereco,
                endereco_uf: this.endereco.endereco_uf.value ? this.endereco.endereco_uf.value : this.endereco.endereco_uf,
                endereco_complemento: this.endereco.endereco_complemento ? this.endereco.endereco_complemento : null
            })
        },

        proximoComprovanteEndereco() {
            this.stopCameraGlobally()
            if (this.comprovanteEndereco?.base64) {
                this.postDocumento({
                    documento_base64: this.comprovanteEndereco.base64,
                    tipo: this.comprovanteEndereco.tipo
                }, (next) => {
                    if (this.comprovanteEnderecoAdicional) {
                        if (this.comprovanteEnderecoAdicional.base64) {
                            this.postDocumento({
                                documento_base64: this.comprovanteEnderecoAdicional.base64,
                                tipo: this.comprovanteEnderecoAdicional.tipo
                            })
                        } else {
                            next()
                        }
                    } else {
                        next()
                    }
                })
            } else {
                this.proximoStep()
            }
        },

        proximoRenda() {
            this.showLoading({ message: 'Salvando seus dados! Por favor, Aguarde!' })
            this.patchManutencao({
                ...this.renda,
                renda_mensal: String(this.renda.renda_mensal).length > 2 ?  String(this.renda.renda_mensal).slice(0, -2) + '.' + String(this.renda.renda_mensal).slice(-2) : this.renda.renda_mensal,
                empresa_telefone: this.renda.empresa_telefone ? this.renda.empresa_telefone.replace(/[^0-9]/g, '') : "",
                empresa_cep: this.renda.empresa_cep ? this.renda.empresa_cep : '',
                empresa_uf: this.renda.empresa_uf.value ? this.renda.empresa_uf.value : this.renda.empresa_uf ? this.renda.empresa_uf : "",
                empresa_cidade: this.renda.empresa_cidade ? this.renda.empresa_cidade : "",
                empresa_endereco: this.renda.empresa_endereco ? this.renda.empresa_endereco : "",
                id_natureza_profissional: this.renda.id_natureza_profissional.value
            }, (next) => {
                if (this.isTotem) {
                    this.processarMotor(next)
                } else {
                    this.proximoStep()
                }
            })
        },

        proximoComprovanteRenda() {
            this.stopCameraGlobally()
            if (this.comprovanteRenda?.base64) {
                this.postDocumento({
                    documento_base64: this.comprovanteRenda.base64,
                    tipo: this.comprovanteRenda.tipo
                }, (next) => {
                    if (this.comprovanteRendaAdicional) {
                        if (this.comprovanteRendaAdicional.base64) {
                            this.postDocumento({
                                documento_base64: this.comprovanteRendaAdicional.base64,
                                tipo: this.comprovanteRendaAdicional.tipo
                            }, (next) => {
                                this.processarMotor(next)
                            })
                        } else {
                            this.processarMotor(next)
                        }
                    } else {
                        this.processarMotor(next)
                    }
                })
            } else {
                this.processarMotor(this.proximoStep)
            }
        },

        patchManutencao(dados, onPatchDone = null) {
            this.showLoading({ message: 'Salvando seus dados! Por favor, Aguarde!' })
            ManutencaoClienteService
                .patch(this.id_manutencao, dados)
                    .then(() => {
                        if (onPatchDone) {
                            onPatchDone(this.proximoStep)
                        } else {
                            this.proximoStep()
                        }
                    }).catch(err => {
                        this.hideLoading()
                        this.errorMessage({
                            title: 'Ops, algo de errado aconteceu!',
                            message: 'Erro atualizar a manutençao de cliente',
                            error: err
                        })
                    })
        },

        postDocumento(dados, onPostDone = null) {
            this.showLoading({ message: 'Salvando seus documentos! Por favor, Aguarde!' })
            ManutencaoClienteService
                .postDocumento(this.id_manutencao, dados.tipo, { documento_base64: dados.documento_base64 })
                    .then(() => {
                        if (onPostDone) {
                            onPostDone(this.proximoStep)
                        } else {
                            this.proximoStep()
                        }
                    }).catch(error => {
                        this.hideLoading()
                        this.errorMessage({
                            title: 'Ops, algo de errado aconteceu!',
                            message: 'Erro ao enviar o documento',
                            error
                        })
                    })
        },

        termoAceito(id_manutencao) {
            this.id_manutencao = id_manutencao

            this.showLoading({ message: 'Carregando os dados! Por favor, Aguarde!' })
            ManutencaoClienteService.getByID(this.id_manutencao, {
                fields: 'analise_credito,status'
            })
                .then(({ data }) => {
                    const status_analise_credito = data.analise_credito
                    if (this.statusAtualizacaoCadastro.includes(status_analise_credito)) {
                        this.step = 1
                        this.hideLoading()
                    } else if (this.statusAtualizacaoB2E.includes(status_analise_credito)) {
                        this.showLoading({ message: 'Processando os dados da manutenção! Por favor, Aguarde!' })
                        this.processarMotor(() => {
                            this.step = 9
                            this.hideLoading()
                        })
                    }
                })
                .catch(() => {
                    this.hideLoading()
                    this.errorMessage({ 
                        title:'Ops! Não foi possivel carregar os dados do usuario',
                        message:"Não foi possivel carregar os dados do usuario na api. Contate a central de credito",
                    })
                })
        },

        processarMotor(onProcessarMotorDone = null) {
            this.showLoading({ message: 'Processando os dados da Manutenção! Por favor, Aguarde!' })
            this.processandoMotor = true
            ManutencaoClienteService.postProcessarMotor(this.id_manutencao)
                .then(() => {
                    this.getDadosManutencao()
                    if (onProcessarMotorDone) {
                        onProcessarMotorDone()
                    }
                })
                .catch(error => {
                    this.statusManutencao = MANUTENCAO_STATUS_AGUARDANDO_INTEGRAR
                    this.errorMessage({ 
                        title:'Ops! Algo de errado aconteceu',
                        message:"Não foi possível concluir a atualização de cadastro. Contate a central de credito",
                        erro: error
                    })
                })
                .finally(() => {
                    this.processandoMotor = false
                    this.hideLoading()
                })
        },

        proximoStep() {
            this.hideLoading()
            this.$refs.stepper.next()
        },

        voltarStep() {
            this.stopCameraGlobally()
            this.$refs.stepper.previous()
        }
    }
}
</script>

<style>
.q-top-space {
    margin-top: 10px
}
</style>
