export default {
    methods: {
        warningMessage({ title, message, duration = 3000 }) {
            this.$q.notify({
                color: 'warning',
                message: `<strong>${title}</strong><br>${message}`,
                position: 'top-right',
                html: true,
                timeout: duration
            })
        },

        errorMessage({ title, message, error }) {
            let content = null
            if (error) {
                try {
                    content = error.response.data.data[Object.keys(error.response.data.data)[0]][0]
                } catch (e) {
                    if (error) {
                        content = error
                    } else {
                        content = null
                    }
                }
            }
            
            this.$q.notify({
                color: 'negative',
                message: `<strong>${title}</strong>${message ? `<br>${message}` : ''}${content ? `<br>${content}` : ''}`, 
                position: 'top-right',
                html: true,
                timeout: 5000
            })
        },

        successMessage({ title, message }) {
            this.$q.notify({
                color: 'positive',
                message: `<strong>${title}</strong><br>${message}`,
                position: 'top-right',
                html: true,
                timeout: 3000
            })
        },

        showLoading(options) {
            this.$q.loading.show(options)
        },

        hideLoading() {
            this.$q.loading.hide()
        }
    }
}
