<template>
  <div
    :class="`row justify-evenly bg-${
      $q.dark.isActive ? 'dark' : 'white'
    } full-width q-pa-sm`"
    style="max-width: 1200px"
  >
    <div class="col">
      <div class="col">
        <div class="row">
          <div class="col-4">
            <p
              class="row text-subtitle2 justify-center text-weight-medium text-blue-grey-4 q-ma-none    "
            >
              Limite Total
            </p>
            <p class="row text-subtitle2 justify-center q-ma-none">
              R$ {{ String(limite.toFixed(2)).replace(".", ",") }}
            </p>
          </div>
          <div class="col-4">
            <p
              class="row text-subtitle2 justify-center text-weight-medium text-blue-grey-4 q-ma-none"
            >
              Limite Utilizado
            </p>
            <p class="row text-subtitle2 justify-center q-ma-none">
              R$ {{ String(utilizado.toFixed(2)).replace(".", ",") }}
            </p>
          </div>
          <div class="col-4">
            <div
              class="row text-subtitle2 justify-center text-weight-medium text-blue-grey-4"
            >
              Limite Disponível *
            </div>
            <div class="row text-subtitle2 justify-center">
              R$ {{ String(disponivel.toFixed(2)).replace(".", ",") }}
            </div>
          </div>
        </div>
        <div class="row justify-center">
          <p class="text-caption text-center text-grey-6 q-ma-none">
            * Atenção, os limites estão sujeitos a análise.
          </p>
        </div>
      </div>
      <div v-if="mostrarMensagem" class="row q-pa-sm">
        <q-separator />
      </div>
      <q-banner-mensagem v-if="mostrarMensagem" :mensagem="mensagem" />
    </div>
  </div>
</template>

<script>
import QBannerMensagem from "@/components/shared/QBannerMensagem";

export default {
  name: "QLimite",

  components: {
    QBannerMensagem,
  },

  props: {
    limite: {
      type: Number,
      default: 0,
    },

    utilizado: {
      type: Number,
      default: 0,
    },

    disponivel: {
      type: Number,
      default: 0,
    },

    mensagem: {
      type: String,
      default: null,
    },
  },

  computed: {
    mostrarMensagem() {
      return this.mensagem !== null;
    },
  },
};
</script>

<style></style>
