<template>
  <div>
    <div class="container-home">
      <div
        :class="`bg-${$q.dark.isActive ? 'dark' : 'white'} q-px-md`"
        style="width: 100%; max-width: 1200px"
      >
        <!-- Mensagens -->
        <div class="q-py-md">
          <q-card v-if="mostrarMensagemTodosUsuarios">
            <q-banner-mensagem :mensagem="mensagemBanner" />
          </q-card>
          <q-card
            v-if="habilitaCriarManutencao && mostrarMensagemAtualizacaoCadastral"
            class="col"
            :class="`bg-${$q.dark.isActive ? 'dark' : 'white'}`"
          >
            <q-banner-mensagem
              mensagem="Identificamos que faz tempo que você não atualiza seus dados."
            />
          </q-card>
          <q-card v-if="mostrarMensagemAtualizacaoCadastralAnalise" class="col">
            <q-banner-mensagem
              mensagem="Há uma atualização de dados do seu cadastro em análise."
            />
          </q-card>
        </div>

        <!-- Buttons -->
        <div class="grid-menu" v-if="loading">
          <q-skeleton
            v-for="(_, index) in Array.from({ length: 12 })"
            :key="index"
            animation="blink"
            type="card"
            style="height: 142px;"
          />
        </div>
        <div class="grid-menu" v-else>
          <q-card
            v-for="item in rotas"
            :key="item.id"
            class="full-width col-2 q-pa-md cursor-pointer card"
            :class="{ 'card-dark': $q.dark.isActive, card: !$q.dark.isActive }"
            @click="item.action"
          >
            <div class="row items-center justify-between">
              <q-icon
                :name="item.icon"
                size="2rem"
                :class="`text-${$q.dark.isActive ? 'white' : 'grey-8'}`"
              />
              <q-icon name="chevron_right" class="text-primary" size="2rem" />
            </div>
            <p
              :class="`title-menu q-pt-xl q-mb-none text-weight-medium text-${
                $q.dark.isActive ? 'white' : 'grey-8'
              }`"
            >
              {{ item.title }}
            </p>
          </q-card>
        </div>

        <!-- Footer -->
        <div :class="`column q-px-md q-pt-md container-exit`">
          <q-btn
            outline
            label="Sair"
            class="text-negative exit-button"
            @click="logout"
            icon="exit_to_app"
          />
          <p class="text-caption q-pt-sm">Versão {{ appVersion }}</p>
        </div>
      </div>
    </div>
    <q-modal-primeira-senha ref="modalPrimeiraSenha" />
  </div>
</template>

<script>
import login from "@/plugins/login";
import ParametroService from "@/services/parametros";
import ParametroPropostaService from "@/services/parametrosProposta";
import ClienteService from "@/services/cliente";
import QBannerMensagem from "@/components/shared/QBannerMensagem";
import QModalPrimeiraSenha from "@/views/PrimeiraSenha";
import MixinMessages from "@/mixins/mixinMessages";
import { EventBus } from '@/plugins/eventBus';

const STATUS_VERIFICACAO_PERFIL_REPROVADA = 3;
const STATUS_SEM_NECESSIDADE_MANUTENCAO = 0;
const ID_VERIFICACAO_ATERASO_15_DIAS_AVALISTA = 5;
const ID_VERIFICACAO_CHEQUE_DEVOLVIDO_ABERTO = 6;

export default {
  name: "Home",

  components: {
    QModalPrimeiraSenha,
    QBannerMensagem,
  },

  mixins: [MixinMessages],

  data() {
    return {
      habilitaMeuBonus: true,
      mensagemBanner: "",
      statusAnalisecredito: null,
      analiseCreditoManutencaoEspontanea: [0],
      analiseCreditoAtualizacaoCadastral: [1, 2, 3],
      analiseCreditoAtualizacaoCadastralAnalise: [6],
      habilitaManutencaoAreaCliente: false,
      verificacoesReprovada: null,
      loading: true,
    };
  },

  created() {
    this.buscaParametros();
  },

  mounted() {
    EventBus.emit('stop-camera');
  },

  computed: {
    rotas() {
      return [
        {
          id: "pagamentos",
          title: "Pagamentos",
          icon: "payments",
          path: "/pagamentos",
          desabilita_dependente: false,
          active: true,
          action: () => this.$router.push("/pagamentos"),
        },
        {
          id: "atualizar_dados",
          title: "Atualizar Dados",
          icon: "sync_alt",
          path: "/cliente",
          desabilita_dependente: false,
          active:
            this.habilitaCriarManutencao &&
            (this.mostrarMensagemAtualizacaoCadastral ||
              this.habilitaManutencaoEspontanea),
          action: () => this.$router.push("/cliente"),
        },
        {
          id: "cartao_virtual",
          title: "Cartão Virtual",
          icon: "credit_card",
          path: "/cartao-virtual",
          desabilita_dependente: false,
          active: true,
          action: () => this.$router.push("/cartao-virtual"),
        },
        {
          id: "meu_bonus",
          title: "Meu Bônus",
          icon: "currency_exchange",
          path: "/meu-bonus",
          desabilita_dependente: false,
          active: this.habilitaMeuBonus,
          action: () => this.$router.push("/meu-bonus"),
        },
        {
          id: "solicitar_senha",
          title: "Solicitar Senha",
          icon: "https",
          path: "/solicitacao-senha",
          desabilita_dependente: true,
          active: true,
          action: () => this.$router.push("/solicitacao-senha"),
        },
        {
          id: "contrato",
          title: "Contrato",
          icon: "assignment",
          path: "/contrato",
          desabilita_dependente: false,
          active: true,
          action: () => this.$router.push("/contrato"),
        },
        {
          id: "nosso_site",
          title: "Nosso Site",
          icon: "store",
          path: "/",
          desabilita_dependente: false,
          active: !this.isTotem,
          action: () => window.open(process.env.VUE_APP_URL_ECOMMERCE),
        },
      ].filter((item) => {
        if (
          item.id === "solicitar_senha" &&
          login.usuarioLogado().id_dependente
        ) {
          return !item.desabilita_dependente;
        }
        return item.active;
      });
    },

    mostrarMensagemTodosUsuarios() {
      return this.mensagemBanner.length > 0;
    },

    rede() {
      return String(process.env.VUE_APP_EMPRESA).toLowerCase() === "pomp"
        ? 1
        : 4;
    },

    habilitaManutencaoEspontanea() {
      return this.analiseCreditoManutencaoEspontanea.includes(
        this.statusAnalisecredito
      );
    },

    mostrarMensagemAtualizacaoCadastral() {
      return this.analiseCreditoAtualizacaoCadastral.includes(
        this.statusAnalisecredito
      );
    },

    mostrarMensagemAtualizacaoCadastralAnalise() {
      return this.analiseCreditoAtualizacaoCadastralAnalise.includes(
        this.statusAnalisecredito
      );
    },

    habilitaCriarManutencao() {
      return this.habilitaManutencaoAreaCliente && !this.verificacoesReprovada;
    },

    usuario() {
      return login.usuarioLogado();
    },

    appVersion() {
      return process.env.VUE_APP_TAG;
    },

    isTotem() {
      return Number(process.env.VUE_APP_TOTEM);
    },
  },

  methods: {
    getLimiteCliente(id) {
      ClienteService.getLimite(id)
        .then(({ data }) => {
          this.statusAnalisecredito = data.analise_manutencao
            ? data.analise_manutencao.status
            : null;
          this.verificacoesReprovada = data.credito_verificacoes_perfis.some(
            (verificacao) =>
              verificacao.status_liberacao ===
              STATUS_VERIFICACAO_PERFIL_REPROVADA
          );
          this.validaVerificacoesReprovadas(
            data.analise_manutencao.status,
            data.credito_verificacoes_perfis
          );
          this.loading = false;
        })
        .catch((e) =>
          this.errorMessage({
            title: "Ops, algo de errado aconteceu!",
            message: "Ocorreu um erro ao buscar o cartão do cliente.",
            error: e,
          })
        )
    },

    validaVerificacoesReprovadas(
      status_analise_manutencao,
      verificacoes_perfis
    ) {
      if (status_analise_manutencao === STATUS_SEM_NECESSIDADE_MANUTENCAO) {
        const hasRelevantVerification = verificacoes_perfis.some(
          (verificacao) =>
            (verificacao.id_verificacao ===
              ID_VERIFICACAO_ATERASO_15_DIAS_AVALISTA &&
              verificacao.status_liberacao ===
                STATUS_VERIFICACAO_PERFIL_REPROVADA) ||
            (verificacao.id_verificacao ===
              ID_VERIFICACAO_CHEQUE_DEVOLVIDO_ABERTO &&
              verificacao.status_liberacao ===
                STATUS_VERIFICACAO_PERFIL_REPROVADA)
        );

        if (hasRelevantVerification) {
          this.mensagemBanner =
            "Entrar em contato com o departamento de crédito para verificação.";
        }
      }
    },

    async buscaParametros() {
      try {
        const [
          habilitaMeuBonusResponse,
          habilitaAreaClienteResponse,
          mensagemBannerInicialResponse,
          habilitaPrimeiraSenhaResponse,
        ] = await Promise.all([
          ParametroService.getParametro("AREA_CLIENTE.HABILITA_MEU_BONUS"),
          ParametroPropostaService.getParametro("MANUTENCAO.HABILITA_MANUTENCAO_AREA_CLIENTE"),
          ParametroService.getParametro("BANNER.MENSAGEM_BANNER_INICIAL"),
          ParametroService.getParametro("CLIENTE.HABILITA_PRIMEIRA_SENHA"),
        ]);

        const { data: habilitaMeuBonus } = habilitaMeuBonusResponse;
        const { data: habilitaAreaCliente } = habilitaAreaClienteResponse;
        const { data: mensagemBannerInicial } = mensagemBannerInicialResponse;
        const { data: habilitaPrimeiraSenha } = habilitaPrimeiraSenhaResponse;

        this.habilitaMeuBonus = Boolean(Number(habilitaMeuBonus[0].valor));
        this.habilitaManutencaoAreaCliente = Boolean(Number(habilitaAreaCliente[0].valor));
        this.mensagemBanner = mensagemBannerInicial[0].valor;

        if (Boolean(Number(habilitaPrimeiraSenha[0].valor)) && this.usuario.criar_senha) {
          this.$refs.modalPrimeiraSenha.open();
        }

        this.getLimiteCliente(this.usuario.id);
      } catch (error) {
        console.error("Erro ao buscar parâmetros:", error);
      }
    },

    logout() {
      login.logout();
    },
  },
};
</script>

<style>
.card:hover {
  background-color: #fafafa;
  transition: ease-in-out 0.3s;
}

.card-dark:hover {
  background-color: #212121;
  transition: ease-in-out 0.3s;
}

.container-home {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-menu {
  font-size: 20px;
}

.exit-button {
  width: 150px;
}

.grid-menu {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}

.container-exit {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 600px) {
  .grid-menu {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 730px) {
  .grid-menu {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 920px) {
  .grid-menu {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 1100px) {
  .grid-menu {
    grid-template-columns: repeat(6, 1fr);
  }
}
</style>
