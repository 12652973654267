<template>
  <div class="row-12">
    <div class="row-12 documento-identificacao-container">
      <q-select
        :class="`${isMobile ? 'type-selector-mobile' : showVerso ? 'type-selector-with-verso' : 'type-selector'} q-mb-sm`"
        v-model="documentType"
        :options="documentOptions"
        label="Tipo de documento"
        square
        filled
      />
      <div class="container-cameras">
        <div class="content-cameras">
          <p v-if="showVerso" class="q-mt-sm text-subtitle2">Frente</p>
          <q-captura-foto @photo="onCapturePhotoFrente" @reset="resetDocuments" />
        </div>
        <div v-if="showVerso" class="content-cameras">
          <p class="q-mt-sm text-subtitle2">Verso</p>
          <q-captura-foto @photo="onCapturePhotoVerso" />
        </div>
      </div>
    </div>
    <q-stepper-navigation>
      <div class="row justify-between">
        <q-btn
          id="btnVoltar"
          class="q-ml-sm btn-proximo"
          color="primary"
          flat
          icon="navigate_before"
          @click="voltar"
          label="Voltar"
        />
        <q-btn
          id="btnProximo"
          class="q-ml-sm btn-proximo"
          color="primary"
          icon-right="navigate_next"
          @click="proximo"
          label="Próximo"
        />
      </div>
    </q-stepper-navigation>
  </div>
</template>

<script>
import QCapturaFoto from "../../shared/QCapturaFoto";
import { EventBus } from '@/plugins/eventBus';

const CNH = 2;
const CTPS = 3;
const CTPS_VERSO = 4;
const RG = 5;
const RG_VERSO = 6;
const OUTROS = 7;

const CNH_PDF = 13;
const CTPS_PDF = 14;
const CTPS_PDF_VERSO = 15;
const RG_PDF = 16;
const RG_PDF_VERSO = 17;
const OUTROS_PDF = 18;

export default {
  name: "stepDocumentoIdentificacao",

  components: {
    QCapturaFoto,
  },

  emits: ["proximo", "voltar", "update:documentoIdentificacaoFrente", "update:documentoIdentificacaoVerso"],

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.resetDocuments();
  },

  data() {
    return {
      base64Frente: null,
      base64Verso: null,
      documentType: {
        label: "CNH",
        value: CNH,
      },
      documentOptions: [
        {
          label: "CNH",
          value: CNH,
        },
        {
          label: "CTPS",
          value: CTPS,
        },
        {
          label: "RG",
          value: RG,
        },
        {
          label: "OUTROS",
          value: OUTROS,
        },
      ],
    };
  },

  computed: {
    isMobile() {
      return this.$q.platform.is.mobile;
    },

    showVerso() {
      return this.base64Frente && (this.documentType.value === CTPS || this.documentType.value === RG)
    }
  },

  watch: {
    documentType: {
      handler() {
        EventBus.emit("reset-camera");
      },
    },
  },

  methods: {
    proximo() {
      this.$emit("proximo");
    },

    voltar() {
      this.$emit("voltar");
    },

    resetDocuments() {
      this.base64Frente = null;
      this.base64Verso = null;
      this.$emit("update:documentoIdentificacaoFrente", null);
      this.$emit("update:documentoIdentificacaoVerso", null);
    },

    onCapturePhotoFrente(data) {
      this.base64Frente = data.base64;
      let tipo = this.documentType.value;
      if (data.extensao === "pdf") {
        switch (tipo) {
          case CNH:
            tipo = CNH_PDF;
            break;
          case CTPS:
            tipo = CTPS_PDF;
            break;
          case RG:
            tipo = RG_PDF;
            break;
          case OUTROS:
            tipo = OUTROS_PDF;
            break;
        }
      }

      this.$emit("update:documentoIdentificacaoFrente", {
        ...data,
        tipo,
      });
    },

    onCapturePhotoVerso(data) {
      this.base64Verso = data.base64;
      let tipo = this.documentType.value;
      
      switch (tipo) {
        case CTPS:
          tipo = CTPS_VERSO;
          break;
        case RG:
          tipo = RG_VERSO;
          break;
      }

      if (data.extensao === "pdf") {
        switch (tipo) {
          case CTPS_VERSO:
            tipo = CTPS_PDF_VERSO;
            break;
          case RG_VERSO:
            tipo = RG_PDF_VERSO;
            break;
        }
      }
      
      this.$emit("update:documentoIdentificacaoVerso", {
        ...data,
        tipo,
      });
    },
  },
};
</script>

<style lang="scss">
.type-selector {
  width: 330px;
  overflow: hidden;
}

.type-selector-mobile {
  width: 300px;
  overflow: hidden;
}

.type-selector-with-verso {
  width: 680px;
  overflow: hidden;
}

.documento-identificacao-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-cameras {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.content-cameras {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
