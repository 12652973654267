<template>
  <div class="row-12">
    <div class="row-12">
      <div class="container-cameras">
        <div class="content-cameras">
          <p v-if="showAdicional" class="q-mt-sm text-subtitle2">Frente</p>
          <q-captura-foto @photo="onCapturePhoto" @reset="resetDocuments" />
        </div>
        <div v-if="showAdicional" class="content-cameras">
          <p class="q-mt-sm text-subtitle2">Adicional</p>
          <q-captura-foto @photo="onCapturePhotoAdicional" />
        </div>
      </div>
    </div>
    <q-stepper-navigation>
      <div class="row justify-between">
        <q-btn
          id="btnVoltar"
          class="q-ml-sm btn-proximo"
          color="primary"
          flat
          icon="navigate_before"
          @click="voltar"
          label="Voltar"
        />
        <q-btn
          id="btnProximo"
          class="q-ml-sm btn-proximo"
          color="primary"
          icon-right="navigate_next"
          @click="proximo"
          label="Próximo"
        />
      </div>
    </q-stepper-navigation>
  </div>
</template>

<script>
import QCapturaFoto from "../../shared/QCapturaFoto";
import { EventBus } from '@/plugins/eventBus';

const ENDERECO = 10;
const ENDERECO_ADICIONAL = 11;
const ENDERECO_PDF = 21;
const ENDERECO_ADICIONAL_PDF = 22;

export default {
  name: "stepComprovanteEndereco",

  components: {
    QCapturaFoto,
  },

  emits: [
    "proximo",
    "voltar",
    "OnComprovanteEndereco",
    "OnComprovanteEnderecoAdicional",
  ],

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.resetDocuments();
  },

  data() {
    return {
      base64: null,
      base64Adicional: null,
    };
  },

  computed: {
    showAdicional() {
      return this.base64;
    },
  },

  methods: {
    proximo() {
      this.$emit("proximo");
    },

    voltar() {
      this.$emit("voltar");
    },

    resetDocuments() {
      this.base64 = null;
      this.base64Adicional = null;
      this.$emit("OnComprovanteEndereco", null);
      this.$emit("OnComprovanteEnderecoAdicional", null);
      EventBus.emit("reset-camera");
    },

    onCapturePhoto(data) {
      this.base64 = data.base64;
      this.$emit("OnComprovanteEndereco", {
        ...data,
        tipo: data.extensao === "pdf" ? ENDERECO_PDF : ENDERECO,
      });
    },

    onCapturePhotoAdicional(data) {
      this.base64Adicional = data.base64;
      this.$emit("OnComprovanteEnderecoAdicional", {
        ...data,
        tipo:
          data.extensao === "pdf" ? ENDERECO_ADICIONAL_PDF : ENDERECO_ADICIONAL,
      });
    },
  },
};
</script>

<style lang="scss">
.container-cameras {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.content-cameras {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
