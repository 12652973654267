<template>
    <div class="row-12">
        <div class="row-12">
            <q-captura-foto
                @photo="onCapturePhoto"
                :mascara="true"
            />
        </div>
        <q-stepper-navigation>
            <div class="row justify-between">
                <q-btn
                    id="btnVoltar"
                    class="q-ml-sm btn-proximo"
                    color="primary"
                    flat
                    icon="navigate_before"
                    @click="voltar"
                    label="Voltar"
                />
                <q-btn
                    id="btnProximo"
                    class="q-ml-sm btn-proximo"
                    color="primary"
                    icon-right="navigate_next"
                    @click="proximo"
                    label="Próximo"
                />
            </div>
        </q-stepper-navigation>
    </div>
</template>

<script>
import QCapturaFoto from '../../shared/QCapturaFoto';

export default {
    name: 'stepFoto',

    components: {
        QCapturaFoto
    },

    emits: [
        'proximo',
        'voltar',
        'update:foto'
    ],

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    methods: {
        proximo() {
            this.$emit('proximo')
        },

        voltar() {
            this.$emit('voltar')
        },

        onCapturePhoto(data) {
            this.$emit('update:foto', {
                ...data,
                tipo: data.extensao === 'pdf' ? 12 : 1
            })
        }
    }
}
</script>

<style lang="scss">
</style>