<template>
    <div class="row-12">
        <div class="row-12">
            <div class="container-cameras">
                <div class="content-cameras">
                    <p v-if="showAdicional" class="q-mt-sm text-subtitle2">Frente</p>
                    <q-captura-foto @photo="onCapturePhoto" @reset="resetDocuments" />
                </div>
                <div v-if="showAdicional" class="content-cameras">
                    <p class="q-mt-sm text-subtitle2">Adicional</p>
                    <q-captura-foto @photo="onCapturePhotoAdicional" />
                </div>
            </div>
        </div>
        <q-stepper-navigation>
            <div class="row justify-between">
                <q-btn
                    id="btnVoltar"
                    class="q-ml-sm btn-proximo"
                    color="primary"
                    flat
                    icon="navigate_before"
                    @click="voltar"
                    label="Voltar"
                />
                <q-btn
                    id="btnProximo"
                    class="q-ml-sm btn-proximo"
                    color="primary"
                    icon-right="navigate_next"
                    @click="proximo"
                    label="Próximo"
                />
            </div>
        </q-stepper-navigation>
    </div>
</template>

<script>
import QCapturaFoto from '../../shared/QCapturaFoto';
import { EventBus } from '@/plugins/eventBus';

const RENDA = 8
const RENDA_ADICIONAL = 9
const RENDA_PDF = 19
const RENDA_ADICIONAL_PDF = 20

export default {
    name: 'stepComprovanteRenda',

    components: {
        QCapturaFoto
    },

    emits: [
        'proximo',
        'voltar',
        'OnComprovanteRenda',
        'OnComprovanteRendaAdicional'
    ],

    props: {
        value: {
            type: Object,
            required: true
        }
    },

    mounted() {
        this.resetDocuments()
    },

    data() {
        return {
            base64: null,
            base64Adicional: null
        }
    },

    computed: {
        showAdicional() {
            return this.base64;
        },
    },

    methods: {
        proximo() {
            this.$emit('proximo')
        },

        voltar() {
            this.$emit('voltar')
        },

        resetDocuments() {
            this.base64 = null;
            this.base64Adicional = null;
            this.$emit("OnComprovanteRenda", null);
            this.$emit("OnComprovanteRendaAdicional", null);
            EventBus.emit("reset-camera");
        },

        onCapturePhoto(data) {
            this.base64 = data.base64
            this.$emit('OnComprovanteRenda', {
                ...data,
                tipo: data.extensao === 'pdf' ? RENDA_PDF : RENDA
            })
        },

        onCapturePhotoAdicional(data) {
            this.base64Adicional = data.base64
            this.$emit('OnComprovanteRendaAdicional', {
                ...data,
                tipo: data.extensao === 'pdf' ? RENDA_ADICIONAL_PDF : RENDA_ADICIONAL
            })
        }
    }
}
</script>

<style lang="scss">
.container-cameras {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.content-cameras {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>