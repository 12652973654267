<template>
  <div class="row-12">
    <q-card
      v-if="!erroB2E"
      class="col row-12 justify-center"
      :class="`bg-${$q.dark.isActive ? 'dark' : 'white'}`"
    >
      <q-banner class="text-center rounded">
        <q-icon :name="iconName" :color="iconColor" size="32px" />
        <p class="text-h6">{{ titleText }}</p>
        <p>{{ descriptionText }}</p>
        <p v-if="reprovado">
          Confira loja mais perto de você
          <a :href="linkNossasLojas" class="text-bold">clicando neste link</a>.
        </p>
      </q-banner>
    </q-card>
    <q-card
      v-else
      class="col row-12 justify-center"
      :class="`bg-${$q.dark.isActive ? 'dark' : 'white'}`"
    >
      <q-banner class="text-center rounded">
        <q-inner-loading :visible="processandoMotor">
          <q-spinner size="75px" color="primary" />
        </q-inner-loading>
        <q-icon :name="iconName" :color="iconColor" size="32px" />
        <p class="text-h6">{{ titleText }}</p>
        <p>{{ descriptionText }}</p>
        <q-btn
          v-if="!processandoMotor"
          class="full-width"
          :color="submiting ? 'secondary' : 'primary'"
          @click="reprocessar"
          :label="`${
            submiting ? mesangemAguardandoTempo : 'Reprocessar Manutenção'
          }`"
          :disabled="submiting"
        />
      </q-banner>
    </q-card>
    <q-btn
      class="full-width q-mt-md"
      color="green"
      to="/home"
      v-if="!processandoMotor && !erroB2E"
    >
      Concluir
    </q-btn>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/eventBus';

export default {
  name: "stepAnalise",

  props: {
    status: {
      type: Number,
      required: true,
    },
    processandoMotor: {
      type: Boolean,
      default: false,
    },
    tempoTentativaManutencao: {
      type: Number,
      default: 0,
    },
    tempoReprocessarManutencao: {
      type: Number,
      default: 0,
    },
    observacaoLoja: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mesangemAguardandoTempo: "Reprocessando Manutenção! Aguarde!",
      submiting: true,
    };
  },

  computed: {
    aprovado() {
      return [1, 2, 9].includes(this.status);
    },

    analise() {
      return [3, 4, 5].includes(this.status);
    },

    reprovado() {
      return [6, 7].includes(this.status);
    },

    erroB2E() {
      return [8].includes(this.status);
    },

    integradoAutomaticamente() {
      return [10].includes(this.status);
    },

    iconName() {
      return this.aprovado || this.integradoAutomaticamente
        ? "done"
        : this.analise || this.erroB2E
        ? "hourglass_empty"
        : this.reprovado
        ? "error"
        : "";
    },

    iconColor() {
      return this.aprovado || this.integradoAutomaticamente
        ? "green"
        : this.analise
        ? "blue"
        : this.reprovado || this.erroB2E
        ? "red"
        : "";
    },

    titleText() {
      return this.aprovado || this.integradoAutomaticamente
        ? "Obrigado! A atualização dos seus dados foi concluída com sucesso."
        : this.analise
        ? "Obrigado por atualizar seus dados, estamos verificando algumas informações, assim que for finalizado, você será notificado via sms."
        : this.reprovado
        ? "Ops, não conseguimos validar seus dados."
        : this.erroB2E
        ? "Estamos processando os dados da manutenção!"
        : "";
    },

    descriptionText() {
      return this.aprovado
        ? "Aproveite e comece a usar agora seu cartão comprando no site ou no app."
        : this.analise
        ? "Enquanto isso, aproveite e acesse nosso site ou app e confira as novidades."
        : this.reprovado
        ? `Você pode tentar novamente em ${this.tempoTentativaManutencao} horas, ou dirija-se até uma de nossas lojas físicas.`
        : this.erroB2E
        ? `Você poderá reprocessar os dados novamente em ${
            this.tempoReprocessarManutencao
          } ${this.tempoReprocessarManutencao > 1 ? "minutos" : "minuto"}`
        : "";
    },

    linkNossasLojas() {
      return process.env.VUE_APP_LINK_NOSSAS_LOJAS;
    },
  },

  mounted() {
    this.iniciarContadorSms();
    EventBus.emit('stop-camera');
  },

  methods: {
    reprocessar() {
      this.$emit("reprocessar", () => {
        this.submiting = true;
        this.iniciarContadorSms();
      });
    },

    iniciarContadorSms() {
      let minutos = this.tempoReprocessarManutencao;
      let segundos = 59;
      let tempoEspera = "";
      minutos--;
      const smsTimer = setInterval(() => {
        segundos--;
        if (segundos < 0) {
          minutos--;
          segundos = 59;
          if (minutos < 0) {
            clearInterval(smsTimer);
            this.submiting = false;
          }
        }
        tempoEspera = `${minutos}:${segundos < 10 ? `0${segundos}` : segundos}`;
        this.mesangemAguardandoTempo = `Você poderá reprocessar a manutenção em: ${tempoEspera}`;
      }, 1000);
    },
  },
};
</script>
